<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none pl-2">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleSidebar">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div></div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import UserDropdown from '@/components/UserDropdown'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    UserDropdown
  },
  props: {
    toggleSidebar: {
      type: Function,
      required: true
    }
  }
}
</script>
