<template>
  <layout-vertical>
    <template #navbar="{ toggleVerticalMenuActive }">
      <div class="w-100">
<!--        <Navbar :toggle-sidebar="toggleVerticalMenuActive" />-->
      </div>
    </template>

    <template #footer>
      <div></div>
    </template>

    <router-view :key="$route.fullPath" />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import Navbar from '@/components/Navbar'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    Navbar
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer
    }
  }
}
</script>
